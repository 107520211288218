<template>
  <div class="mt-1 mx-3">
    <breakdowns>
    </breakdowns>
  </div>
</template>

<script>
import Breakdowns from '@/components/profile/companies-products/breakdowns/index.vue'
export default {
  props: {
    msg: String
  },
  components: {
    Breakdowns,
  },
  created() {
  }
}
</script>