<template>
  <div class="mt-1 mx-3">
    <register-breakdown></register-breakdown>
  </div>
</template>

<script>
import RegisterBreakdown from "@/components/profile/companies-products/breakdowns/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    RegisterBreakdown,
  },
  created() {
    this.notifyQueue({
      text: "Scorpori",
      path: "module.PROF",
      tab: "#Breakdowns",
      level: 3,
    });
    this.notifyQueue({
      text: "Inserisci Scorpori",
      path: "breakdowns.create",
      level: 4,
    });
  },
};
</script>
